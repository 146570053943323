body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ellipse {
  stroke: none;
  fill: none;
}

.float_top {
  animation: float_top 6s ease-in-out infinite;
}

.float_bottom {
  animation: float_bottom 6s ease-in-out infinite;
}

.tv_test screen{
  fill: repeating-linear-gradient(#111, #111 50%, #fff 50%)
}

.inner-scroll {
    height: 25em;
    max-height: 25em;
    overflow-y: scroll;
}

@keyframes float_top {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(10px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes float_bottom {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}